<template>
  <el-form class="login-form" status-icon :rules="loginRules" ref="loginForm" :model="userForm" label-width="0">
    <el-form-item prop="account">
      <el-input size="small" @keyup.enter.native="handleLogin" v-model="userForm.account" auto-complete="off"
        :placeholder="$t('login.username')">
        <i slot="prefix" class="icon-yonghu"><img src="../../assets/img/account.png" /></i>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input size="small" @keyup.enter.native="handleLogin" :type="passwordType" v-model="userForm.password"
        auto-complete="off" :placeholder="$t('login.password')">
        <i class="el-icon-view el-input__icon" slot="suffix" @click="showPassword"></i>
        <i slot="prefix" class="icon-mima"><img src="../../assets/img/password.png" /></i>
      </el-input>
    </el-form-item>
    <el-form-item prop="captcha">
      <el-input size="small" @keyup.enter.native="handleLogin" v-model="userForm.captcha" auto-complete="off"
        :placeholder="$t('login.captcha')">
        <i class="el-input__icon" slot="suffix" @click="getCaptchaCode">
          <img :src="codeImg" />
        </i>
        <i slot="prefix" class="icon-mima"><img src="../../assets/img/code.png" /></i>
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="small" @click.native.prevent="handleLogin"
        class="login-submit">{{ $t('login.submit') }}</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { getCaptcha } from '../../request/conlog.js';
export default {
  name: "userlogin",
  data() {
    return {
      userForm: {
        account: "admin",
        password: "",
        captcha: "",
        captcha_key: ''

      },
      loginRules: {
        tenantId: [
          { required: false, message: this.$t(`enterUserId`), trigger: "blur" }
        ],
        account: [
          { required: true, message: this.$t(`enterAdmin`), trigger: "blur" }
        ],
        password: [
          { required: true, message: this.$t(`enterPassword`), trigger: "blur" },
          { min: 1, message: this.$t(`chagePassword`), trigger: "blur" }
        ],
        captcha: [
          { required: true, message: this.$t(`enterCaptcha`), trigger: "blur" },
        ]
      },
      passwordType: "password",
      codeImg: '',
    };
  },
  mounted() {
    this.getCaptchaCode()
  },
  methods: {
    getCaptchaCode() {
      getCaptcha().then(res => {
        this.codeImg = res.data.image;
        this.userForm.captcha_key = res.data.key;
        this.userForm.captcha = ''
      })
    },
    showPassword() {
      this.passwordType == ""
        ? (this.passwordType = "password")
        : (this.passwordType = "");
    },
    handleLogin() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          const data = await this.$store.dispatch('login/userLoginToken', this.userForm);
          if (data.errcode === 200) {
            this.$router.push('/layout')
          } else {
            this.$message({
              message: data.errmsg,
              type: ''
            });
            this.getCaptchaCode()
          }
        }
      });
    }
  }
};
</script>
<style >
.login-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: url("https://test.swap.id/file/20214128134100.jpg") 0 bottom repeat-x #049ec4;
  animation: animate-cloud 20s linear infinite;
}
.login-weaper {
  margin: 0 auto;
  width: 1000px;
  box-shadow: -4px 5px 10px rgba(0, 0, 0, 0.4);
}
.login-weaper .el-input-group__append {
  border: none;
}
.login-left,
.login-border {
  position: relative;
  min-height: 500px;
  align-items: center;
  display: flex;
}
.login-left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  justify-content: center;
  flex-direction: column;
  background-color: #409EFF;
  color: #fff;
  float: left;
  width: 50%;
  position: relative;
}
.login-left .img {
  width: 140px;
}
.login-time {
  position: absolute;
  left: 25px;
  top: 25px;
  width: 100%;
  color: #fff;
  font-weight: 200;
  opacity: 0.9;
  font-size: 18px;
  overflow: hidden;
}
.login-left .title {
  margin-top: 60px;
  text-align: center;
  color: #fff;
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 25px;
}
.login-border {
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
  background-color: #fff;
  width: 50%;
  float: left;
  box-sizing: border-box;
}
.login-main {
  margin: 0 auto;
  width: 65%;
  box-sizing: border-box;
}
.login-main>h3 {
  margin-bottom: 20px;
}
.login-main>p {
  color: #76838f;
}
.login-title {
  color: #0563EB;
  margin-bottom: 60px;
  font-weight: bold;
  font-size: 34px;
  text-align: center;
}
.login-menu {
  margin-top: 40px;
  width: 100%;
  text-align: center;
}
.login-menu a {
  color: #999;
  font-size: 12px;
  margin: 0px 8px;
}
.login-submit {
  width: 100%;
  height: 45px;
  border: 1px solid #409EFF;
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 300;
  color: #FFFFFF;
  cursor: pointer;
  margin-top: 30px;
  font-family: "neo";
  transition: 0.25s;
  background: #0563EB;
  box-shadow: 0px 20px 30px 0px rgba(5, 99, 235, 0.35);
}
.login-form {
  margin: 10px 0;
}
.login-form .el-input {
  border: 1px solid #EEEEEE;
}
.login-form .el-input input {
  padding-bottom: 25px;
  padding-top: 25px;
  padding-left: 40px;
  text-indent: 5px;
  background: transparent;
  border: none;
  border-radius: 0;
  color: #333;
}
.login-form .el-input.el-input__prefix i {
  padding: 0 5px;
  font-size: 16px !important;
}
.el-form-item__content {
  width: 100%;
}
.el-form-item {
  margin-bottom: 20px;
}
.login-code {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 0 0 10px;
}
.login-code-img {
  margin-top: 2px;
  width: 100px;
  height: 38px;
  background-color: #fdfdfd;
  border: 1px solid #f0f0f0;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 5px;
  line-height: 38px;
  text-indent: 5px;
  text-align: center;
}
.icon-yonghu img {
  height: 20px;
  margin-bottom: -10px;
  padding: 0 8px;
}
.icon-mima img {
  height: 20px;
  margin-bottom: -10px;
  padding: 0 8px;
}
</style>
