import request from "./axios.js";


/**
 * 近七天消费里程
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getWeekConsume(data) {
    return request.get("/index/weekConsume",{params:data} );
}

/**
 * 近七天骑行电动车数
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getWeekConsumeScootere(data) {
  return request.get("/index/weekConsumeScooter",{params:data} );
}

/**
 * 近七天订单
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getWeekOrder(data) {
  return request.get("/index/weekOrder",{params:data} );
}


/**
 * 实时统计
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getRightData(data) {
  return request.get("/index/panelData",{params:data} );
}

/**
 * 设备数据
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getDeviceData(data) {
  return request.get("/index/deviceData",{params:data} );
}

/**
 * 数据占比
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getPercentage(data) {
  return request.get("/index/percentage",{params:data} );
}


/**
 * 里程模块
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getWeekRouter(data) {
  return request.get("/index/weekRoute",{params:data} );
}


/**
 * 电动车模块
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getWeekScooter(data) {
  return request.get("/index/weekScooter",{params:data} );
}

/**
 * 
 * 电池模块
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getWeekBattery(data) {
  return request.get("/index/weekBattery",{params:data} );
}


/**
 * 换电柜模块
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getWeekStation(data) {
  return request.get("/index/weekStation",{params:data} );
}

/**
 * 用户模块
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getWeekUser(data) {
  return request.get("/index/weekUser",{params:data} );
}


/**
 * 地图数据模块
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getScooterMapData(data) {
  return request.get("/index/scooterLocation",{params:data} );
}


export function getStationMapData(data) {
  return request.get("/index/stationLocation",{params:data} );
}

/**
 * 减少碳排放
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getReduceConsume(data) {
  return request.get("/index/reduceConsume",{params:data} );
}

/**
 * 24小时
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getHouersData(data) {
  return request.get("/index/selectHours",{params:data} );
}

/**
 * 历史 电动车
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getHistoryWeekScooter(data) {
  return request.get("/history/addScooter",{params:data} );
}
/**
 * 历史 换电次数/换电充电柜数
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getHistoryStation(data) {
  return request.get("/history/station",{params:data} );
}
/**
 * 历史 电池
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getHistoryBattery(data) {
  return request.get("/history/battery",{params:data} );
}
/**
 * 历史 用户
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getHistoryUser(data) {
  return request.get("/history/user",{params:data} );
}
/**
 * 历史 里程
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getHistoryMileage(data) {
  return request.get("/history/mileage",{params:data} );
}
/**
 * 历史 订单数量/订单金额
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getHistoryOrder(data) {
  return request.get("/history/order",{params:data} );
}


/**
 * 历史 用电量
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getHistoryElectricity(data) {
  return request.get("/history/electricity",{params:data} );
}

/**
 * 历史 用电量
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getHistoryStationmapTop(data) {
  return request.get("/stationmap/top",{params:data} );
}

/**
 * 电动车库存占比
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getScooterPercentage() {
  return request.get("/index/scooterPercentage");
}

/**
 * 电池库存占比
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getStationPercentage() {
  return request.get("/index/stationPercentage");
}

/**
 * 换电柜库存占比
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getBatteryPercentage() {
  return request.get("/index/batteryPercentage");
}

/**
 * 换电柜库存占比
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getWeekRecord() {
  return request.get("/index/weekRecord");
}

/**
 * 近30天电动车轨迹热力图
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getShooter( data ) {
  return request.get("/heatmap/route", {params: data });
}

/**
 * 近30天电动车轨迹热力图
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getRoute( data ) {
  return request.get("/heatmap/v2/route", {params: data });
}

/**
 * 近七天活跃电动车/里程
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getWeekScooterAndConsume() {
  return request.get("/index/weekScooterAndConsume");
}

/**
 * 电动车详情
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getScooterdetail(data) {
  return request.get("/index/scooter/detail",{params:data} );
}
/**
 * 点位详情
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getPointDetail(data) {
  return request.get("/index/point/detail",{params:data} );
}
/**
 * 点位搜索
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getPointSearch(data) {
  return request.get("/index/point/search",{params:data} );
}

/**
 * 近30天电动车轨迹区域排行
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getHeatmapRank(data) {
  return request.get("/heatmap/rank",{params:data});
}

/**
 * 电动车排行榜
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getScooterRank() {
  return request.get("/heatmap/scooterRank");
}
/**
 * 区域换电柜数量
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getStationRank() {
  return request.get("/stationmap/map");
}

/**
 * 区域换电柜次数
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getStationmap() {
  return request.get("/stationmap/number");
}

/**
 * 近三十天轨迹途径各省份次数
 *
//  * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getHeatmapProvince(data) {
  return request.get("/heatmap/province",{params:data});
}

/**
 * 近6月月消费里程/月充值里程
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getMothScooterAndConsume() {
  return request.get("/index/mothMileageAndRecharge");
}

/**
 * 在运营换电柜
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getOperationStation(data) {
  return request.get("/history/operationStation", {params:data});
}

/**
 * 近6月日活跃车辆/日换电车辆
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getMothScooterAndCount() {
  return request.get("/index/mothScooterAndCount");
}

/**
 * 近6月月充值的订单/月订单总额
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getMothOrder(data) {
  return request.get("/index/mothOrder");
}

/**
 * 历史换电电量频次
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getPowerFrequency(data) {
  return request.get("/index/powerFrequency");
}

/**
 * 近6月日换电次数/日换电充电柜数
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getMothRecord(data) {
  return request.get("/index/mothRecord");
}

/**
 * 活跃/换电车辆
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getHistoryScooter(data) {
  return request.get("/history/scooter", {params:data});
}

/**
 * 活跃/换电车辆总数
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getHistoryScooterTotals() {
  return request.get("/history/scooterTotals");
}

/**
 * 换电次数总数
 *
 */
 export function getStationTotals() {
  return request.get("/history/stationTotals");
}

/**
 * 运营换电柜
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getOperationsStation(data) {
  return request.get("/history/operationsStation", {params:data});
}

// /**
//  * 
//  *
//  * @param {Object} data - get对象数据{params:{a:1,b:2}}
//  */
//  export function getSse() {
//   return request.get("/test/sse");
// }

/**
 * 获取验证码
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getCaptcha() {
  return request.get("/SysUser/captcha");
}

/**
 * 排行榜
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getStationmapTop(data) {
  return request.get("/stationmap/top", {params:data});
}
/**
 * 充值套餐数量
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getMileagePackagesNumber(data) {
  return request.get("/history/mileagePackagesNumber", {params:data});
}
/**
 * 点位电池数量
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getPointBatteryNum() {
  return request.get("/index/pointBatteryNum");
}
/**
 * 点位坐标和在线状态
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
 export function getPointLocation() {
  return request.get("/index/pointLocation");
}































/**
 * 订单数量模块
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
// export function getWeekScooter(data) {
//   return request.get("/index/weekScooter",{params:data} );
// }

/**
 * 订单金额模块
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
// export function getWeekRouter(data) {
//   return request.get("/index/weekRoute",{params:data} );
// }

/**
 * 用电量模块
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
// export function getWeekScooter(data) {
//   return request.get("/index/weekScooter",{params:data} );
// }

/**
 * 故障模块
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
// export function getWeekScooter(data) {
//   return request.get("/index/weekScooter",{params:data} );
// }








/**
 * 新闻添加
 *
 * @param {Object} data - 对象数据
 */
export function addNewsData(data) {
  return request.post("/index/news", data);
}

/**
 * 新闻查询id
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getNewsDataId(data,id) {
  return request.get("/index/news/"+id,{params:data} );
}

/**
 * 新闻删除
 *
 * @param {Object} data - 对象数据
 */
export function deleteNewsDataId(data,id) {
  return request.delete("/index/news/"+id,{params: data});
}


/**
 * 新闻修改
 *
 * @param {Object} data - 对象数据
 */
export function editNewsDataId(data,id) {
  return request.put("/index/news/"+id,data);
}

