<template>
  <div style="position: relative;">
    <div class="logo">
      <img src="./../../assets/img/swap_logo.png" alt="">
    </div>
    <div class="login_pc">
      <div class="grid-content bg-purple">
        <div class="login-box">
          <div class="login-item">
            <h4 class="login-title">Please Sign In</h4>
            <userLoginbox></userLoginbox>
          </div>
        </div>
      </div>
    </div>
    <div class="login_app"></div>
  </div>
</template>
<script>
import userLoginbox from "./userlogin";
export default {
  name: "login",
  data() {
    return {
      userForm: {
        domains: [
          {
            value: "",
          },
        ],
        account: "",
      },
      loginForm: {
        account: "",
        password: "",
      },
      urlCode: "",
    };
  },
  components: {
    userLoginbox,
  },
  mounted() {
    localStorage.setItem('activeName', '1')
  },
  methods: {
    // submitForm(formName) {
    //   this.$refs[formName].validate(async (valid) => {
    //     if (valid) {
    //       const data = await this.$store.dispatch(
    //         "login/userLoginToken",
    //         this.userForm
    //       );
    //       if (data.errcode === 200) {
    //         this.$router.push("/layout");
    //       } else {
    //         this.$message({
    //           message: data.errmsg,
    //           type: "",
    //         });
    //       }
    //     } else {
    //       return false;
    //     }
    //   });
    // },
    // resetForm(formName) {
    //   this.$refs[formName].resetFields();
    // },
    // removeDomain(item) {
    //   var index = this.userForm.domains.indexOf(item);
    //   if (index !== -1) {
    //     this.userForm.domains.splice(index, 1);
    //   }
    // },
  },
};
</script>
<style>
.el-divider--horizontal {
  width: 92%;
  margin: 24px auto;
}
.grid-content {
  width: 400px;
  margin-bottom: -0.8rem;
  display: flex;
  align-items: center;
  height: 100vh;
  margin-right: 15%;
  float: right;
}
.login-box {
  width: 100%;
  display: flex;
  background-color: #fff;
}
.login-item {
  width: 100%;
  text-align: center;
  margin: 80px 0 120px;
  padding: 0 40px;
}
.login-item.swap {
  margin: 0;
  font-size: 0.01rem;
  background-color: #091140;
  border-top-left-radius: 0.02rem;
  border-bottom-left-radius: 0.02rem;
}
.login-item.swap img {
  width: 0.9rem;
  margin-top: 0.5rem;
}
.login-time {
  text-align: left;
  margin: 0.03rem;
}
.title {
  font-size: 0.12rem;
  color: #fff;
  margin-top: 0.1rem;
}
.button-login {
  margin-left: -64px;
}
.input-login {
  margin-left: -40px;
  width: 86%;
}
.gird-span {
  min-height: 80px;
}
.logo {
  position: absolute;
  font-size: 12px !important;
  margin: 50px 0 0 50px;
}
</style>

